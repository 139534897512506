import React, { useEffect } from 'react';
import NotFoundPage from 'templates/ErrorPage/404';
import { trackSystemError } from 'helpers/eventTracking';
import { ErrorType } from 'state/error/actions';

const NotFound: React.FC = () => {
  useEffect(() => trackSystemError(404, ErrorType.NOT_FOUND), []);
  return <NotFoundPage />;
};

export default NotFound;
